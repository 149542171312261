<template>
    <div>
        <app-table
            :caption="tableCaption"
            :columns="columns"
            :actions="tableActions"
            :data="tickets"
            :loading="gettingTickets"
            :total-server-items="meta.total"
            @update:page="handlePageUpdated"
            @refresh="init({
                page: $route.query.page || 1
            })"
            @search="handleSearch"
            ref="table"
        >
            <template v-slot:item.number="{ item }">
                <div class="tw-flex tw-items-center tw-space-x-2">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-on="on"
                                v-bind="attrs"
                                small
                                :color="item.priority ? item.priority.color : null"
                            >fas fa-square</v-icon>
                        </template>
                        <span>{{ item.priority ? item.priority.title : 'Unknown priority' }}</span>
                    </v-tooltip>
                    <span>{{ item.number }}</span>
                </div>
            </template>
            <template v-slot:item.title="{ item }">
                <router-link
                    :to="{
                            name: 'tickets.details',
                            params: {
                                ticketId: item.id,
                            }
                        }"
                >{{ item.title }}</router-link>
            </template>
        </app-table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AppTable from '@/components/Table.vue';

    export default {
        components: {
            AppTable,
        },
        data() {
            return {
                columns: [
                    {
                        text: 'Ticket #',
                        value: 'number',
                        width: 110,
                        slotName: 'item.number',
                    },
                    {
                        text: 'Title',
                        value: 'title',
                        slotName: 'item.title',
                    },
                    {
                        text: 'Department',
                        value: 'department.title',
                        sortable: false,
                    },
                    {
                        text: 'Type',
                        value: 'type.title',
                        sortable: false,
                    },
                    {
                        text: 'Status',
                        value: 'status.title',
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: 'Last Update',
                        value: 'updated_at_format_c',
                        sortable: false,
                        width: 180,
                    }
                ],
                tableActions: [
                    {
                        label: 'Details',
                        icon: 'fal fa-info-circle fa-fw',
                        handler: (ticket) => {
                            this.$router.push({
                                name: 'tickets.details',
                                params: { ticketId: ticket.id },
                            });
                        }
                    },
                ],
            }
        },
        computed: {
            ...mapGetters({
                tickets: 'ticket/tickets',
                gettingTickets: 'ticket/gettingTickets',
                meta: 'ticket/meta',
                priorities: 'ticket/priorities',
                descriptionFormats: 'ticket/descriptionFormats',
            }),
            tableCaption() {
                let prefix = 'All';

                if (this.$route.query.department_title) {
                    prefix = this.$route.query.department_title;
                }

                return `${prefix} Tickets`;
            },
        },
        watch: {
            $route(next, prev) {
                if (next.query.department !== prev.query.department) {
                    this.init({
                        page: this.$route.query.page || 1,
                        q: this.$route.query.q,
                    })
                }
            }
        },
        mounted() {
            if (this.tickets.length === 0) {
                this.init({
                    page: this.$route.query.page || 1,
                    q: this.$route.query.q,
                });
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (from.name !== to.name) {
                    vm.init({
                        page: vm.$route.query.page || 1,
                        q: vm.$route.query.q,
                    });
                }
            })
        },
        methods: {
            init({ page, q }) {
                this.$store.dispatch('ticket/getTickets', {
                    page,
                    q,
                    department: this.$route.query.department,
                    query: {
                        ticket: [
                            'id',
                            'title',
                            'description',
                            'status',
                            'number',
                            'type',
                            'department',
                            'updated_at_for_human',
                            'priority',
                            'description_format',
                        ],
                        ticket_type: [
                            'title',
                        ],
                        ticket_department: [
                            'title',
                        ],
                        ticket_priority: [
                            'id',
                            'color',
                            'title',
                        ],
                        ticket_status: [
                            'title',
                        ]
                    },
                })
            },
            handlePageUpdated(page) {
                this.init({ page })
            },
            handleSearch(q) {
                this.init({
                    page: this.$route.query.page || 1,
                    q,
                });
            }
        }
    }
</script>
